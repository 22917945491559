/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/poppins/200.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/900.css"
import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"

import "./src/styles/global.css"

import constants from "./src/lib/constants"

console.log(`gm. the contract is located at ${constants.contractAddr}`)
